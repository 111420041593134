<script>
export default {
  name: "FooterComponent",
};
</script>

<template>
  <footer class="container">
    <div class="content">
      <div class="quick-links">
        <router-link to="/" class="logo">
          <img src="@/assets/logo_dark.png" alt="logo" />
        </router-link>
        <p>
          AEI est une Junior-Entreprise de l'ENSEIRB-MATMECA, école d'ingénieurs
          en électronique, informatique et mathématiques appliquées.
        </p>
        <div class="socials">
          <a
            href="https://www.linkedin.com/company/aquitaine-electronique-informatique/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="ri-linkedin-fill"></i>
          </a>
          <a
            href="https://www.facebook.com/JuniorAEI/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="ri-facebook-fill"></i>
          </a>
          <a
            href="https://www.instagram.com/junioraei/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i class="ri-instagram-fill"></i>
          </a>
        </div>
      </div>

      <div class="nav-links">
        <router-link to="/" class="nav-link">
          <i class="ri-information-2-fill"></i>
          <p>À Propos</p>
        </router-link>
        <router-link to="/team" class="nav-link">
          <i class="ri-team-fill"></i>
          <p>Notre Équipe</p>
        </router-link>
        <router-link to="/skills" class="nav-link">
          <i class="ri-tools-fill"></i>
          <p>Nos Compétences</p>
        </router-link>
        <!-- <router-link to="/news" class="nav-link">
          <i class="ri-newspaper-fill"></i>
          <p>Actualités</p>
        </router-link> -->
        <router-link to="/contact" class="nav-link">
          <i class="ri-chat-smile-3-fill"></i>
          <p>Nous contacter</p>
        </router-link>
      </div>

      <div class="news-letter">
        <h3>
          <i class="ri-mail-ai-line"></i>
          <p>Inscrivez-vous à notre newsletter !</p>
        </h3>
        <p>
          Recevez les dernières actualités et offres exclusives de notre
          Junior-Entreprise !
        </p>
        <form action="">
          <input type="email" placeholder="Votre adresse email" />
          <button>S'inscrire</button>
        </form>
      </div>
    </div>

    <p class="cr">
      Copyright &copy; Aquitaine Electronique Informatique 1987 - 2024
    </p>
  </footer>
</template>

<style scoped>
* {
  /* outline: 1px solid red; */
}

footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  gap: 2rem;
}

.content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-around;
  align-items: center;
  gap: 4rem;
  width: 100%;
}

.quick-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 200px; */
  /* width: 400px; */
  gap: 1.5rem;

  .logo {
    display: flex;
    align-items: center;
    width: 200px;

    & img {
      width: 100%;
      object-fit: contain;
    }

    transition: all 0.3s ease-in-out;
    &:hover {
      /* drop shadow */
      filter: drop-shadow(0 0 5px rgba(47, 39, 206, 1));
    }
  }

  & p {
    font-size: 1rem;
    opacity: 0.8;
  }

  & .socials {
    display: flex;
    gap: 1rem;

    & a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
      border-radius: 50%;
      background-color: var(--grey);

      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: var(--secondary);

        & i {
          color: var(--primary);
        }
      }

      & i {
        font-size: 1.5rem;
        color: var(--text);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  gap: 0.8rem;
  /* width: 300px; */
  /* height: 200px; */

  & .nav-link {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.2rem;
    border-radius: 5px;
    font-size: 1.2rem;
    gap: 1rem;
    background-color: var(--grey);

    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: var(--secondary);
      & p,
      & i {
        color: var(--primary);
      }
    }
    & p,
    & i {
      transition: all 0.3s ease-in-out;
    }
  }
}

.news-letter {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  /* height: 200px; */
  /* width: 500px; */
  gap: 1.5rem;

  & h3 {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & i {
      font-size: 1.5rem;
      color: var(--primary);
    }

    & p {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  & p {
    font-size: 1rem;
    opacity: 0.8;
  }

  & form {
    /* outline: 1px solid red; */
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    gap: 1rem;
    background-color: #fff;
    padding: 0.5rem;
    border-radius: 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    & input {
      /* outline: 1px solid red; */
      padding: 0.5rem 1rem;
      border-radius: 100px;
      width: 100%;
      border: none;

      &:focus {
        outline: none;
      }
    }

    & button {
      padding: 0.5rem 1rem;
      border-radius: 100px;
      background-color: var(--grey);
      color: var(--text);
      font-weight: 500;
      cursor: pointer;
      border: none;

      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: var(--secondary);
        color: var(--primary);
      }
    }
  }
}

.cr {
  font-weight: 500;
  opacity: 0.5;
  color: var(--text);
}
</style>
