<script>
import NavigationComponent from "@/components/Navigation.vue";
import FooterComponent from "@/components/Footer.vue";
import "remixicon/fonts/remixicon.css";

export default {
  components: {
    NavigationComponent,
    FooterComponent,
  },
};
</script>

<!-- Animations -->
<script setup>
import { onMounted, nextTick, watch } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();

const initObserver = () => {
  if (window.__fadeObserver) {
    window.__fadeObserver.disconnect(); // Cleanup old observer
  }

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        const effectClass = [...entry.target.classList].find((cls) =>
          cls.startsWith("animation-")
        );
        if (!effectClass) return;

        const activeClass = `${effectClass}--active`;

        if (entry.isIntersecting) {
          entry.target.classList.add(activeClass);
        } else {
          entry.target.classList.remove(activeClass);
        }
      });
    },
    { root: null, threshold: 0, rootMargin: "0px 0px -15% 0px" }
  );

  const applyObserver = () => {
    document
      .querySelectorAll("[class*='animation-']")
      .forEach((el) => observer.observe(el));
  };

  applyObserver(); // Apply to existing elements

  // 🔹 Reapply observer when new elements are added to the DOM
  if (!window.__mutationObserver) {
    window.__mutationObserver = new MutationObserver(() => {
      applyObserver();
    });

    window.__mutationObserver.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  window.__fadeObserver = observer; // Store globally for debugging
};

onMounted(() => {
  nextTick(() => {
    initObserver();
  });
});

// 🟢 Re-run the observer whenever the route changes (fixes hot reload issue)
watch(route, () => {
  nextTick(() => {
    initObserver();
  });
});
</script>

<template>
  <NavigationComponent />

  <router-view />

  <FooterComponent />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

/* Colors */
:root {
  --text: rgb(5, 3, 21);
  --background: rgb(251, 251, 254);
  --grey: rgb(238, 238, 242);
  --primary: rgb(47, 39, 206);
  --secondary: rgb(195, 221, 255);
  --accent: rgb(67, 59, 255);

  --error: rgb(233, 105, 105);
  --success: rgb(147, 226, 147);
}

/* Resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  color: var(--text);
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  /* line-height: 1; */
  /* letter-spacing: 0.5px; */
}

a {
  text-decoration: none;
  color: var(--text);
}

ul {
  list-style: none;
}

/* General */
html {
  background-color: var(--background);
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  overflow-x: hidden;
}

.container {
  width: 100%;
  max-width: 1600px;
}

/* Animations */

.animation-fade-in {
  opacity: 0;
  filter: blur(5px);
  transform: translateY(20px);

  transition: opacity, transform, filter;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.animation-fade-in--active {
  opacity: 1;
  transform: translateY(0);
  filter: blur(0);
}

.animation-grow {
  transform: scale(0.8);
  opacity: 0;
  filter: blur(5px);

  transition: transform, opacity, filter;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
}

.animation-grow--active {
  transform: scale(1);
  opacity: 1;
  filter: blur(0);
}

@media (prefers-reduced-motion) {
  .animation-fade-in {
    transition: none;
  }

  .animation-grow {
    transition: none;
  }
}
</style>
