import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

createApp(App).use(router).mount("#app");

// Utility functions

function scrollToElement(className, offsetPercent = 0) {
  const element = document.querySelector(`.${className}`);

  if (!element) {
    console.warn(`Element with class "${className}" not found.`);
    return;
  }

  const viewportHeight = window.innerHeight;
  const offsetPx = (offsetPercent / 100) * viewportHeight;

  const elementPosition = element.getBoundingClientRect().top + window.scrollY;
  const targetScrollPosition = elementPosition - offsetPx;

  window.scrollTo({
    top: targetScrollPosition,
    behavior: "smooth",
  });
}

export { scrollToElement };
