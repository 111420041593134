<script>
export default {
  name: "NavigationComponent",
};
</script>

<template>
  <nav class="">
    <router-link to="/" class="logo">
      <img src="@/assets/logo_light.png" alt="logo" />
    </router-link>

    <div class="nav-links">
      <router-link to="/">À Propos</router-link>
      <router-link to="/team">Notre Équipe</router-link>
      <router-link to="/skills">Nos Compétences</router-link>
      <!-- <router-link to="/news">Actualités</router-link> -->
      <router-link to="/contact">Contact</router-link>
    </div>
  </nav>
</template>

<style scoped>
nav {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  width: 95%;
  top: 2vh;
}

nav .logo {
  display: flex;
  align-items: center;
  width: 150px;

  & img {
    width: 100%;
    object-fit: contain;
  }

  transition: all 0.3s ease-in-out;
  &:hover {
    /* drop shadow */
    filter: drop-shadow(0 0 5px rgba(5, 3, 21, 0.5));
  }
}

.nav-links {
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  & a {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.2rem;
    border-radius: 100px;
    font-size: 1.2rem;
    background-color: var(--background);

    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: var(--secondary);
      color: var(--primary);
    }
  }
}
</style>
