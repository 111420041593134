<script>
// import HelloWorld from "@/components/HelloWorld.vue";
import { scrollToElement } from "@/main.js";

export default {
  name: "HomeView",
  components: {
    // HelloWorld,
  },
  methods: {
    scrollToElement(element, offset) {
      scrollToElement(element, offset);
    },
  },
};
</script>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";

// Hero Carousel

const sentences = [
  "Développement de solutions logicielles et web",
  "Sécurisation des réseaux et cybersécurité",
  "Développement de solutions embarquées et automatisées",
  "Modélisation et simulation mécanique avancée",
  "Développement d'applications en IA et Machine Learning",
];

const currentIndex = ref(0);
let intervalId = null;

const nextSentence = () => {
  currentIndex.value = (currentIndex.value + 1) % sentences.length;
};

const goToSentence = (index) => {
  currentIndex.value = index;
  restartInterval();
};

const restartInterval = () => {
  clearInterval(intervalId);
  intervalId = setInterval(nextSentence, 5000); // Switch every 4s
};

onMounted(() => {
  intervalId = setInterval(nextSentence, 5000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<template>
  <div class="background-hero">
    <img src="@/assets/bordeaux.jpg" alt="hero" />
    <div class="mask1"></div>
    <!-- <div class="mask2"></div> -->
  </div>

  <div class="hero container">
    <h1 class="slogan">AEI, votre partenaire technologique depuis 1987 !</h1>
    <div class="cta-group">
      <div class="cta" @click="scrollToElement('presentation', 35)">
        <i class="ri-arrow-down-line"></i> Découvrir notre Junior-Entreprise
      </div>
      <router-link to="/contact" class="cta">
        <i class="ri-money-euro-circle-fill"></i> Demander un devis
      </router-link>
    </div>

    <div class="help-carousel">
      <h2>Comment pouvons-nous vous aider ?</h2>

      <p class="sentence">{{ sentences[currentIndex] }}</p>

      <div class="dots">
        <span
          v-for="(sentence, index) in sentences"
          :key="index"
          :class="{ active: index === currentIndex }"
          @click="goToSentence(index)"
        ></span>
      </div>
    </div>
  </div>

  <section class="presentation container animation-fade-in">
    <i class="ri-double-quotes-l"></i>
    <p>
      Depuis sa création en 1987, AEI s'est imposée comme un acteur clé dans
      l'accompagnement des entreprises grâce à l'expertise de nos étudiants
      ingénieurs. Découvrez comment notre ambition et notre passion pour
      l'innovation technologique ont façonné notre parcours.
    </p>
    <i class="ri-double-quotes-r"></i>
  </section>

  <section class="big-numbers container">
    <!-- <div class="background"></div> -->
    <h2 class="animation-grow">AEI en quelques chiffres</h2>
    <div class="numbers">
      <div class="number animation-fade-in">
        <h3>37</h3>
        <p>ans d'expérience</p>
      </div>
      <div class="number animation-fade-in">
        <h3>+600</h3>
        <p>projets réalisés</p>
      </div>
      <div class="number animation-fade-in">
        <h3>1200</h3>
        <p>intervenants possibles</p>
      </div>
    </div>
  </section>

  <section class="valeurs container">
    <div class="background"></div>

    <h2 class="animation-grow">Nos valeurs</h2>
    <div class="values">
      <div class="value animation-fade-in">
        <i class="ri-lightbulb-flash-line"></i>
        <h3>Innovation</h3>
        <p>
          Apportez le regard neuf de futurs ingénieurs qui vivent pleinement
          dans la technologie et l'innovation et en seront acteurs majeurs
          demain !
        </p>
      </div>
      <div class="value animation-fade-in">
        <i class="ri-crosshair-2-line"></i>
        <h3>Engagement</h3>
        <p>
          Les étudiants de par leur formation font preuve d'un engagement sans
          faille afin de vous satisfaire !
        </p>
      </div>
      <div class="value animation-fade-in">
        <i class="ri-thumb-up-line"></i>
        <h3>Qualité</h3>
        <p>
          Grâce à une formation exigeante les intervenants et administrateurs
          mettront tout en œuvre afin d'obtenir le meilleur résultat possible.
        </p>
      </div>
    </div>
  </section>

  <section class="nous-choisir container">
    <h2 class="animation-grow">Pourquoi nous choisir ?</h2>
    <div class="reasons">
      <div class="reason animation-fade-in">
        <i class="ri-graduation-cap-line"></i>
        <h3 class="title">
          <p>Des étudiants ingénieurs</p>
        </h3>
        <p>
          100% de nos réalisateurs suivent une formation d'ingénieur à
          l'ENSEIRB-MATMECA.
        </p>
      </div>
      <div class="reason animation-fade-in">
        <i class="ri-history-line"></i>
        <h3 class="title">
          <p>3 mois de garantie</p>
        </h3>
        <p>
          Un problème concernant le travail d'un de nos réalisateurs ? Aucun
          problème, AEI vous suit jusqu'à 3 mois après le versement final.
        </p>
      </div>
      <div class="reason animation-fade-in">
        <i class="ri-shake-hands-line"></i>
        <h3 class="title">
          <p>Toutes tailles d'études</p>
        </h3>
        <p>
          Vous avez un gros projet et n'osez pas nous le confier ? Prenez
          rendez-vous avec notre chef de projet qui saura vous rassurer sur nos
          compétences.
        </p>
      </div>
    </div>
  </section>

  <section class="logo-banner container">
    <h1 class="animation-grow">Ils nous ont fait confiance</h1>
    <div class="logos">
      <div class="fade-left"></div>
      <div class="fade-right"></div>

      <div class="logos-slide">
        <a href="https://mabanque.bnpparibas/" target="_blank"
          ><img src="@/assets/logo/01.png" alt="Company 1"
        /></a>
        <a href="https://junior-entreprises.com/" target="_blank"
          ><img src="@/assets/logo/02.png" alt="Company 2"
        /></a>
        <a href="https://enseirb-matmeca.bordeaux-inp.fr/fr" target="_blank"
          ><img src="@/assets/logo/03.jpg" alt="Company 3"
        /></a>
        <a href="https://www.alten.fr/" target="_blank"
          ><img src="@/assets/logo/04.png" alt="Company 4"
        /></a>
        <a href="https://www.sncf-connect.com/" target="_blank"
          ><img src="@/assets/logo/05.png" alt="Company 5"
        /></a>
        <a href="https://www.thalesgroup.com/fr" target="_blank"
          ><img src="@/assets/logo/06.png" alt="Company 6"
        /></a>
        <a href="https://www.pickup.fr/" target="_blank"
          ><img src="@/assets/logo/07.png" alt="Company 7"
        /></a>
        <a href="https://www.edf.fr/" target="_blank"
          ><img src="@/assets/logo/08.png" alt="Company 8"
        /></a>
        <a href="https://france3-regions.francetvinfo.fr/" target="_blank"
          ><img src="@/assets/logo/09.webp" alt="Company 9"
        /></a>
        <a href="https://www.dassault-aviation.com/fr/" target="_blank"
          ><img src="@/assets/logo/10.svg" alt="Company 10"
        /></a>
        <a href="https://www.airbus.com/en" target="_blank"
          ><img src="@/assets/logo/11.png" alt="Company 11"
        /></a>
        <a href="https://www.lcl.fr/" target="_blank"
          ><img src="@/assets/logo/12.png" alt="Company 12"
        /></a>
      </div>

      <!-- Duplicate for seamless animation -->
      <div class="logos-slide">
        <a href="https://mabanque.bnpparibas/" target="_blank"
          ><img src="@/assets/logo/01.png" alt="Company 1"
        /></a>
        <a href="https://junior-entreprises.com/" target="_blank"
          ><img src="@/assets/logo/02.png" alt="Company 2"
        /></a>
        <a href="https://enseirb-matmeca.bordeaux-inp.fr/fr" target="_blank"
          ><img src="@/assets/logo/03.jpg" alt="Company 3"
        /></a>
        <a href="https://www.alten.fr/" target="_blank"
          ><img src="@/assets/logo/04.png" alt="Company 4"
        /></a>
        <a href="https://www.sncf-connect.com/" target="_blank"
          ><img src="@/assets/logo/05.png" alt="Company 5"
        /></a>
        <a href="https://www.thalesgroup.com/fr" target="_blank"
          ><img src="@/assets/logo/06.png" alt="Company 6"
        /></a>
        <a href="https://www.pickup.fr/" target="_blank"
          ><img src="@/assets/logo/07.png" alt="Company 7"
        /></a>
        <a href="https://www.edf.fr/" target="_blank"
          ><img src="@/assets/logo/08.png" alt="Company 8"
        /></a>
        <a href="https://france3-regions.francetvinfo.fr/" target="_blank"
          ><img src="@/assets/logo/09.webp" alt="Company 9"
        /></a>
        <a href="https://www.dassault-aviation.com/fr/" target="_blank"
          ><img src="@/assets/logo/10.svg" alt="Company 10"
        /></a>
        <a href="https://www.airbus.com/en" target="_blank"
          ><img src="@/assets/logo/11.png" alt="Company 11"
        /></a>
        <a href="https://www.lcl.fr/" target="_blank"
          ><img src="@/assets/logo/12.png" alt="Company 12"
        /></a>
      </div>
    </div>
  </section>
</template>

<style scoped>
/* Hero Section */
.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 2rem;

  & h1 {
    font-size: 4rem;
    text-align: center;
    color: var(--background);
    font-weight: 500;
    width: 60%;
    line-height: 1.5;
  }
}

.cta-group {
  display: flex;
  gap: 4rem;
  margin-top: 2rem;

  & i {
    font-size: 1.5rem;
    font-weight: 500;
    margin-right: 0.5rem;
    color: var(--background);
  }

  & .cta {
    padding: 1rem 2rem;
    border-radius: 100px;
    background-color: rgba(255, 255, 255, 0.1);
    outline: 1px solid var(--background);
    backdrop-filter: blur(10px);
    color: var(--background);
    font-size: 1.2rem;
    font-weight: 500;
    cursor: pointer;

    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

/* Carousel */

.help-carousel {
  position: absolute;
  bottom: 10%;
  z-index: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  margin-top: 2rem;

  & h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--background);
    opacity: 0.8;
  }

  .sentence {
    margin-top: 0.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--background);
    transition: opacity 0.6s ease-in-out;
  }
}

.dots {
  display: flex;
  justify-content: center;
  gap: 0.8rem;
  margin-top: 1rem;
  opacity: 0.8;
}

.dots span {
  width: 10px;
  height: 10px;
  background-color: var(--background);
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  transform-origin: center;

  &:hover {
    transform: scale(1.1);
    background-color: var(--secondary);
  }
}

.dots span.active {
  width: 20px;
}

/* Hero Background */

.background-hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  /* overflow: hidden; */

  & img,
  & .mask1 {
    position: absolute;
    top: 1vh;
    left: 0.5vw;
    width: 99vw;
    height: 98vh;
    border-radius: 20px;
  }

  & img {
    object-fit: cover;
    filter: brightness(0.7);
  }

  & .mask1 {
    backdrop-filter: blur(5px);
  }

  & .mask2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: conic-gradient(
      from var(--angle) at 50% 50%,
      rgba(47, 39, 206, 1) 0deg,
      rgba(251, 251, 254, 1) 100deg,
      rgba(47, 39, 206, 1) 360deg
    );
    filter: blur(10px);
    animation: spin 10s linear infinite;
  }
}

/* var angle */
@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

/* Content */

section {
  margin: 5rem 0;
}

.presentation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10rem;
  gap: 2rem;
  padding: 4rem 0;
  border-radius: 1rem;
  background: linear-gradient(
    90deg,
    rgba(238, 238, 242, 0),
    rgba(238, 238, 242, 1),
    rgba(238, 238, 242, 0)
  );

  & i {
    font-size: 5rem;
    color: var(--accent);
    opacity: 0.3;
  }

  & p {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: center;
    width: 60%;
  }
}

.big-numbers {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5rem 0 15rem;
  gap: 3rem;
  top: 5rem;

  & h2 {
    font-size: 3rem;
    font-weight: 500;
  }

  & .background {
    position: absolute;
    width: 100vw;
    height: 120%;
    z-index: -1;
    background: radial-gradient(
      circle,
      rgba(67, 59, 255, 0.3) 0%,
      rgba(67, 59, 255, 0) 100%
    );
  }
}

.numbers {
  display: flex;
  gap: 10rem;

  & .number {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* gap: 1rem; */

    & h3 {
      font-size: 4rem;
      font-weight: 600;
      color: var(--accent);
    }

    & p {
      font-size: 1.2rem;
      font-weight: 400;
      text-align: center;
    }

    &:nth-child(1) {
      transition-delay: 0;
    }
    &:nth-child(2) {
      transition-delay: 200ms;
    }
    &:nth-child(3) {
      transition-delay: 400ms;
    }
  }
}

.valeurs {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  gap: 3rem;
  top: 5rem;

  & h2 {
    font-size: 3rem;
    font-weight: 500;
  }

  & .values {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 4rem;
  }

  & .value {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    & i {
      font-size: 3rem;
      font-weight: 300;
      color: var(--primary);
    }

    & h3 {
      font-size: 1.5rem;
      font-weight: 500;
    }

    & p {
      font-size: 1.2rem;
      text-align: center;
      line-height: 1.5;
    }

    transition: all 0.3s ease-in-out;
    &:hover {
      transition-delay: 0s !important;
      scale: 1.02;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
    }

    &:nth-child(1) {
      transition-delay: 200ms;
    }
    &:nth-child(2) {
      transition-delay: 400ms;
    }
    &:nth-child(3) {
      transition-delay: 600ms;
    }
  }
}

.valeurs .background {
  position: absolute;
  width: 100vw;
  height: 120%;
  z-index: -1;
  background: radial-gradient(
    circle,
    rgba(67, 59, 255, 0.3) 0%,
    rgba(67, 59, 255, 0) 100%
  );
}

.nous-choisir {
  /* outline: 1px solid red; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin: 20rem 0 10rem;

  & h2 {
    transition: all 0.3s ease-in-out;
    font-size: 3rem;
    font-weight: 500;
  }

  & .reasons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
  }

  & .reason {
    /* outline: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    height: 250px;
    padding: 2rem;
    border-radius: 1rem;

    box-shadow: inset -5px -5px 15px rgba(0, 0, 0, 0.2),
      5px 5px 15px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;

    &:hover {
      transition-delay: 0s !important;
      scale: 1.01;
      box-shadow: inset -5px -5px 15px rgba(0, 0, 0, 0.2),
        10px 10px 30px rgba(0, 0, 0, 0.3);
    }

    & i {
      font-size: 2.5rem;
      font-weight: 300;
      color: var(--primary);
    }
    & .title {
      display: flex;
      align-items: center;
      gap: 1rem;

      & p {
        font-size: 1.5rem;
        font-weight: 500;
      }
    }

    & p {
      font-size: 1.2rem;
      text-align: center;
    }

    &:nth-child(1) {
      transition-delay: 200ms;
    }
    &:nth-child(2) {
      transition-delay: 400ms;
    }
    &:nth-child(3) {
      transition-delay: 600ms;
    }
  }
}

/* Logo Banner */

/* .logo-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin: 5rem 0 15rem 0;
  padding: 40px 0;

  & h1 {
    font-size: 3rem;
    font-weight: 500;
  }
} */

/* .logo-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 5rem 0 15rem 0;

  & h1 {
    font-size: 3rem;
    font-weight: 500;
  }
} */

.logo-banner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3rem;
  text-align: center;
  padding: 40px 0;
  overflow: hidden;
  position: relative;
  margin: 5rem 0 15rem 0;

  & h1 {
    font-size: 3rem;
    font-weight: 500;
  }

  & .fade-left,
  & .fade-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    background: linear-gradient(to left, transparent, var(--background) 90%);
    z-index: 1;
    pointer-events: none;
  }

  & .fade-right {
    right: 0;
    transform: rotate(180deg);
  }

  & .fade-left {
    left: 0;
  }
}

.logos {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.logos-slide {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-shrink: 0;
  min-width: 100%;
  animation: slide 20s linear infinite;
}

.logos-slide a {
  margin: 0 20px;
}

.logos-slide img {
  height: 75px;
  max-width: 150px;
  object-fit: contain;
  filter: sepia(1) hue-rotate(200deg);
  overflow: visible;

  transition: all 0.3s ease-in-out;
  &:hover {
    scale: 1.1;
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

/* Ensure the animation looks smooth */
.logos:hover .logos-slide {
  animation-play-state: paused;
}
</style>
